import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import CodeVerifier from '../_common/code-verifier.vue';
import Cookie from 'js-cookie';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.cnPrivacy = false;
        this.form = {
            login_type: 'phone',
            username: '',
            password: '',
        };
        this.errorText = '';
        this.isLoading = false;
        this.isRememberMe = false;
    }
    get hasCheckbox() {
        return (this.form.login_type === 'phone' && this.form.username.includes('86-'));
    }
    onReminderReady(userInfo) {
        if (userInfo) {
            const userInfoObj = JSON.parse(userInfo);
            if (userInfoObj.login_type === 'phone') {
                this.isRememberMe = true;
                this.form = {
                    ...this.form,
                    ...JSON.parse(userInfo),
                };
            }
        }
    }
    // 处理记住账密码
    handleRememberMe() {
        if (this.isRememberMe) {
            KLOOK.LS.set('loginInfo', JSON.stringify(Object.assign(this.form, {
                password: '',
            })));
        }
        else {
            KLOOK.LS.remove('loginInfo');
        }
    }
    handleLoginSuccess() {
        Cookie.set('isLogin', '1', {
            path: '/',
            expires: 5,
        });
        this.handleRememberMe();
        this.$router.push({ name: 'home' });
    }
    // 登录
    login() {
        const verifier = this.$refs.codeVerifier;
        this.errorText = '';
        this.isLoading = true;
        verifier
            .verify(false)
            .then((res) => {
            this.$ajax
                .post(URLS.loginWithPhoneCode, {
                login_id: res.username,
                code: res.verify_code,
            }, false)
                .then(() => {
                this.form.username = res.username;
                this.handleLoginSuccess();
            })
                .catch((err) => {
                this.errorText = err;
            })
                .finally(() => {
                this.isLoading = false;
            });
        })
            .catch(() => {
            this.isLoading = false;
        });
    }
    created() {
        const loginInfo = KLOOK.LS.get('loginInfo');
        this.onReminderReady(loginInfo);
    }
};
Login = tslib_1.__decorate([
    Component({
        components: {
            CodeVerifier,
        },
    })
], Login);
export default Login;
